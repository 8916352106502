import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { OrganizationsComponent } from './admin/organizations/organizations.component';
import { TenantsComponent } from './admin/tenants/tenants.component';
import { AcceptInviteComponent } from './features/accept-invite/accept-invite.component';
import { BillingComponent } from './features/billing/billing.component';
import { CallbackComponent } from './features/callback/callback.component';
import { DashboardComponent } from './features/dashboard/dashboard.component';
import { InviteFailureComponent } from './features/invite-failure/invite-failure.component';
import { LandingComponent } from './features/landing/landing.component';
import { LoggedOutComponent } from './features/logged-out/logged-out.component';
import { LoginFailureComponent } from './features/login-failure/login-failure.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { OnboardComponent } from './features/onboard/onboard.component';
import { OrgSelectorComponent } from './features/org-selector/org-selector.component';
import { PermissionFailureComponent } from './features/permission-failure/permission-failure.component';
import { UsersComponent } from './features/users/users.component';
import { activeOrgGuard } from './guards/active-org.guard';
import { featureFlagGuard } from './guards/feature-flag.guard';
import { permissionGuard, platformPermissionGuard } from './guards/permission-guard';
import { verifyUserGuard } from './guards/verify-user.guard';

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard, verifyUserGuard],
  },
  {
    path: 'callback',
    component: CallbackComponent,
    pathMatch: 'full',
  },
  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: 'logged-out',
    component: LoggedOutComponent,
  },
  {
    path: 'login-failure',
    component: LoginFailureComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'invite',
    component: AcceptInviteComponent,
  },
  {
    path: 'invite-failure',
    component: InviteFailureComponent,
  },
  {
    path: 'permission-failure',
    component: PermissionFailureComponent,
  },
  {
    path: 'onboard',
    component: OnboardComponent,
  },
  {
    path: 'select-org',
    component: OrgSelectorComponent,
    canActivate: [AuthGuard, verifyUserGuard],
  },
  {
    path: 'org/:organization',
    canActivate: [AuthGuard, verifyUserGuard, activeOrgGuard],
    pathMatch: 'prefix',
    children: [
      {
        path: 'billing',
        component: BillingComponent,
        canActivate: [featureFlagGuard('billing_frontend'), permissionGuard('GET_BILLING')],
      },
      {
        path: 'users',
        canActivate: [permissionGuard('GET_ORG_USER')],
        component: UsersComponent,
      },
      {
        path: 'tenants',
        component: TenantsComponent,
        canActivate: [platformPermissionGuard('CREATE_TENANT')],
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'admin',
        pathMatch: 'prefix',
        children: [
          {
            path: 'org',
            component: OrganizationsComponent,
            canActivate: [platformPermissionGuard('CREATE_ORG')],
          },
          { path: 'tenants', component: TenantsComponent },
        ],
      },
    ],
  },
  { path: 'dashboard', redirectTo: '', pathMatch: 'full' },
  { path: '**', redirectTo: '404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
