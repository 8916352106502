import { AuthConfig } from '@auth0/auth0-angular';

import { environment as env, environment } from '../environments/environment';

export const auth0Configuration: AuthConfig = {
  // this allows us to explicitly handle the callback
  // that helps us prevent navigation race conditions
  skipRedirectCallback: true,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  useRefreshTokensFallback: true,
  ...env.auth,
  httpInterceptor: {
    allowedList: [
      {
        uri: `${environment.apiBaseUrl}*`,
      },
    ],
  },
};
