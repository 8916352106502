import { Component } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

import { getOrgSlug } from '../../guards/active-org.guard';
import { SelfService } from '../../services/self/self.service';

@Component({
  selector: 'app-landing',
  template: '',
})
export class LandingComponent {
  constructor(
    private selfService: SelfService,
    private router: Router,
  ) {
    const self$ = toObservable(this.selfService.selfSignal);
    self$.subscribe(self => {
      const activeOrgs = self?.organizations.filter(org => org.status === 'active') || [];

      if(activeOrgs.length > 1) {
        this.router.navigate(['select-org']);
      }
      else {
        this.router.navigate(['org', getOrgSlug(activeOrgs[0]), 'dashboard']);
      }
    })
  }
}
