import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-callback',
  template: '',
  styles: [],
})
export class CallbackComponent {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.handleRedirectCallback().subscribe({
      next: (callbackResult) => {
        const appState = callbackResult.appState;
        const comingFromErrorPage = appState?.target?.includes('failure') || appState?.target?.includes('404');

        if(appState?.target && !comingFromErrorPage) {
          this.router.navigate([appState.target], { queryParams: appState['queryParams'] });
        } else {
          this.router.navigate(['']);
        }

      },
      error: (err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        this.router.navigate(['login-failure']);
      },
    });
  }
}
