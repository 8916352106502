import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-accept-invite',
  template: '',
  styles: [],
})
export class AcceptInviteComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(qParams => {

      this.authService.loginWithRedirect({
        appState: {
          target: '/onboard',
          queryParams: qParams,
        },
        authorizationParams: {
          prompt: 'login', // forces re-login
        },
      })
    })
  }

}
