import { inject } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { catchError, combineLatest, filter, of, switchMap, tap } from 'rxjs';

import { ExpandedUser } from '../models/users.types';
import { SelfService } from '../services/self/self.service';

/**
 * Checks if the user is authenticated
 * redirects to login if not
 *
 * Then checks if the user has a self record with active organizations
 * redirects to `login-failure` if not
 */
export const verifyUserGuard: CanActivateFn = (snapshot, state) => {
  const selfService = inject(SelfService);
  const router = inject(Router);
  const auth = inject(AuthService);

  // since we're pulling from tanstack's call we need to glue the data and error feed back together
  const selfSignal$ = toObservable(selfService.selfSignal);
  const selfErrorSignal$ = toObservable(selfService.selfQuery.error);

  const self$ = combineLatest([selfSignal$, selfErrorSignal$]).pipe(
    switchMap(([self, error]) => {
      if(error) {
        throw error;
      }
      return of(self);
    }),
  )

  return auth.isAuthenticated$.pipe(
    tap((loggedIn) => {
      if (!loggedIn) {
        auth.loginWithRedirect({
          appState: { target: state.url },
        });
      }
    }),
    switchMap(() => self$),
    filter((self): self is ExpandedUser => Boolean(self)),
    switchMap(user => {
      const hasOrgs = user.organizations && user.organizations.length > 0;
      const hasActiveOrgs = user.organizations.some(org => org.status === 'active');

      const validUser = hasOrgs && hasActiveOrgs;

      if (!validUser) {
        throw new Error('User has no active organizations');
      }

      return of(validUser);
    }),
    catchError(() => {
      router.navigate(['login-failure']);
      return of(false);
    }),
  );
}
