import pkg from '../../package.json';

export const environment = {
  production: false,
  devTools: false,
  name: 'infdev',
  sentry_dsn: 'https://9f2548143c235960190bbc2b49111910@o347616.ingest.us.sentry.io/4507074137423872',
  package_name: pkg.name,
  package_version: pkg.version,
  apiBaseUrl: 'https://manager.infdev.cen.flywheel.io',
  apiFeedUrl: 'https://feed.infdev.cen.flywheel.io',
  tenantBaseUrl: '.infdev.ten.flywheel.io',
  auth: {
    domain: 'auth.infdev.cen.flywheel.io',
    cookieDomain: '.flywheel.io',
    clientId: 'pJLzcEFVmEcRpGWgT0KMhpq8OijsWI6J',
    authorizationParams: {
      audience: 'https://central-management-infdev',
      redirect_uri: `${window.location.origin}/callback`,
      scope: 'openid profile email offline_access',
    },
  },
};
